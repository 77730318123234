import React from "react"
import Layout from "../components/Layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

const pageQuery = graphql`
  query About {
    graphCmsAbout {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      description {
        html
      }
    }
  }
`

export default function About() {
  const {
    graphCmsAbout: { description, image },
  } = useStaticQuery(pageQuery)
  return (
    <Layout
      title="Katharina Kohler | About me"
      description="Katharina Kohler - M.Sc. psychologist and artist, architectural psychology, digital and analog art, Berlin, Germany"
    >
      <div className="grid grid-cols-1 lg:grid-cols-8 lg:mt-12">
        <GatsbyImage
          image={getImage(image.localFile)}
          alt="Portrait of Katharina Kohler"
          className="col-span-3"
        />
        <div className="col-span-5 lg:px-4 relative mt-28 sm:mt-40">
          <div
            className="text-justify font-medium text-lg tracking-normal leading-relaxed space-y-4"
            dangerouslySetInnerHTML={{ __html: description.html }}
          ></div>
          <h1 className="absolute -top-48 right-0 lg:-top-52 lg:-left-40 lg:right-auto text-7xl lg:text-8xl text-right text-flieder font-black z-10">
            ABOUT
            <br />
            ME
          </h1>
        </div>
      </div>
    </Layout>
  )
}
